<template>
  <div>
  <router-view />
</div>
</template>

<script>

export default {
  name: 'medic',
  components: {
  }
}
</script>
<style lang="less" scoped>
</style>
